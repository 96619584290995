export class WyszukiwarkaToggler{

    constructor() {
        this.wyszukiwarkaform = document.getElementById('wyszukiwarka-form');
    }

    toggle(){
        const visibility = this.wyszukiwarkaform.getAttribute("data-visable");
        if (visibility === "false") {
            this.wyszukiwarkaform.setAttribute("data-visable", true);
            this.wyszukiwarkaform.classList.remove('hidden');
            document.getElementById('default-search').focus();
        } else if (visibility === "true") {
            this.wyszukiwarkaform.setAttribute("data-visable", false);
            this.wyszukiwarkaform.classList.add('hidden');
        }
    }
}