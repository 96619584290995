export class DarkModeToggler{
    constructor() {
        //this.html = document.querySelector('html');
        this.themeToggleDarkIcon = document.getElementById('theme-toggle-dark-icon');
        this.themeToggleLightIcon = document.getElementById('theme-toggle-light-icon');
    }

    setClass(){
        if (localStorage.getItem('color-theme') === 'dark') {
            this.themeToggleDarkIcon.classList.remove('hidden');
            document.documentElement.classList.add('dark');

        } else {
            this.themeToggleLightIcon.classList.remove('hidden');
            document.documentElement.classList.remove('dark');
        }
    }

    toggle(){
        this.themeToggleDarkIcon.classList.toggle('hidden');
        this.themeToggleLightIcon.classList.toggle('hidden');

        if (localStorage.getItem('color-theme')) {
            if (localStorage.getItem('color-theme') === 'light') {
                document.documentElement.classList.add('dark');
                localStorage.setItem('color-theme', 'dark');
            } else {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('color-theme', 'light');
            }

        } else {
            if (document.documentElement.classList.contains('dark')) {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('color-theme', 'light');
            } else {
                document.documentElement.classList.add('dark');
                localStorage.setItem('color-theme', 'dark');
            }
        }
    }
}