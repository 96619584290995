export class FontSizeToggler{
    constructor() {
        this.html = document.querySelector('html');
        this.toggleFontPlusIcon = document.getElementById('toggle-font-plus');
        this.toggleFontMinusIcon = document.getElementById('toggle-font-minus');
    }

    setClass(){
        if (localStorage.getItem('font-size') === 'middle') {
            this.html.classList.add('middlefont');
            this.toggleFontPlusIcon.classList.remove('hidden');

        } else if (localStorage.getItem('font-size') === 'big') {
            this.html.classList.add('bigfont');
            this.toggleFontMinusIcon.classList.remove('hidden');

        } else {
            this.toggleFontPlusIcon.classList.remove('hidden');

        }
    }

    toggle(){

        if (localStorage.getItem('font-size')) {

            if (localStorage.getItem('font-size') === 'middle') {
                localStorage.setItem('font-size', 'big');
                this.html.classList.remove('middlefont');
                this.html.classList.add('bigfont');
                this.toggleFontPlusIcon.classList.add('hidden');
                this.toggleFontMinusIcon.classList.remove('hidden');

            } else if(localStorage.getItem('font-size') === 'big') {
                this.html.classList.remove('bigfont');
                localStorage.removeItem('font-size');
                this.toggleFontMinusIcon.classList.add('hidden');
                this.toggleFontPlusIcon.classList.remove('hidden');

            } else {
                localStorage.setItem('font-size', 'middle');
                this.html.classList.add('middlefont');

            }

        } else {
            localStorage.setItem('font-size', 'middle');
            this.html.classList.add('middlefont');

        }

    }
}